import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import {
  BLOG_STORIES_LIST,
  CURRENT_OPENING,
} from "../../../_eaFruitsCms/https/ApiRoutes";
import { metaImage, toAbsoluteUrl } from "../../../helper/AssetHelpers";
import HeroBanner from "../../../layout/components/banners/heroBanner";
import ApplyCard from "../../../layout/components/cards/applyCard";
import CareerCard from "../../../layout/components/cards/careerCard";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import NoRecords from "../../../layout/components/noRecords/noRecords";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";
import IsLoading from "../../components/Loader";

const Career = (props: any) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleCategory = () => setIsOpen(!isOpen);

  const [currentOpenings, setCurrentOpenings] = useState<any>([]);
  const [stories, setStories] = useState<any>([]);
  const [limit, setLimit] = useState<any>(2);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [imageLoad, setImageLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchCurrentOpenings().then();
    // fetchEmployeeStories().then();
    goToTop(setDisplayLoader);
  }, []);

  const fetchCurrentOpenings = async () => {
    setDisplayLoader(true);
    setIsLoading(true);
    const response = await getRequest(CURRENT_OPENING, navigate, false);
    setCurrentOpenings(response.results);
    setDisplayLoader(false);
    setIsLoading(false);
  };

  const fetchEmployeeStories = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      BLOG_STORIES_LIST +
        `?limit=${limit}&usertype=Employee&ordering=-updated_at`,
      false,
      navigate
    );

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    setStories(response.results);
    setDisplayLoader(false);
  };

  return (
    <>
      <CustomHelmet
        title={`Join Our Team - Current Career Opportunities at EA Foods`}
        metaDescription={`Begin your career at EA foods. We are always open for talented team members. Be a part of something big! View our job openings and apply now.`}
        imagePath={metaImage}
      />
      {/* {displayLoader && <CustomLoader />} */}
      <HeroBanner
        heroTitle="Join Our Team"
        heroDesc={
          <>
            At EA Foods We offer an opportunity to continuously grow, leveraging
            modern technologies, moving away from stacks of paperwork, and make
            a positive impact on people's lives by empowering smallholder farmer
            & local vendors. As a young, lean EAF member, you'll have the
            opportunity to proactively build our systems and processes and leave
            their fingerprints on our culture. We discover and build gems,
            high-quality employees, for EAF, and EAF gems go on to be gems
            elsewhere in the continent, taking skills and success beyond today
            into tomorrow both personally and professionally
            <p className="mt-2">
              EAF is your growth pillar, manifest your vision and we'll usher
              you there by making you realize the potential you have inside with
              mentorship and guidance.
            </p>
          </>
        }
        isButton={true}
        isButton2={true}
        buttonText="Apply Now"
        buttonText2="View Our Openings"
        href={"#openings"}
        path="/media/images/herocareer.jpg"
        heroImageMobile="/media/images/heroCareerM.jpg"
      />

      <div className="py-5"></div>

      <section className="section-padding">
        <Container>
          <Row className="leftImageContent align-items-center">
            <Col md={6}>
              <div className="primary-shadow left-shadow rounded">
                <div className="position-relative flex-grow-1">
                  <img
                    src={toAbsoluteUrl("/media/images/career1.jpg")}
                    srcSet={toAbsoluteUrl("/media/images/career1@2x.jpg 2x")}
                    className="rounded img-fluid"
                    alt=""
                    onLoad={() => {
                      setImageLoad(true);
                    }}
                  />
                  {!imageLoad && <span className="skeleton-box"></span>}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="ps-md-5">
                <h2 className="font-20 fw-700 mb-10 mt-4 mt-md-0">
                  Be Proud of Where You Work
                </h2>
                <p className="mb-30px">
                  We have developed a strong culture of service over the years
                  of operation that has seen EA Foods grow. Our company is
                  fuelled by our employees who are fired up to work at a company
                  they’re proud of.
                </p>
              </div>
            </Col>
          </Row>
          {/* row end */}
        </Container>
      </section>
      {/* right side image section start */}
      <section className="section-padding pt-0">
        <Container>
          <Row className="rightImageContent align-items-center flex-md-row-reverse">
            <Col md={6}>
              <div className="primary-shadow right-shadow rounded">
                <div className="position-relative flex-grow-1">
                  <img
                    src={toAbsoluteUrl("/media/images/career2.jpg")}
                    srcSet={toAbsoluteUrl("/media/images/career2@2x.jpg 2x")}
                    className="rounded img-fluid"
                    alt=""
                    onLoad={() => {
                      setImageLoad(true);
                    }}
                  />
                  {!imageLoad && <span className="skeleton-box"></span>}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="pe-md-5">
                <h2 className="font-20 fw-700 mb-10 mt-4 mt-md-0">
                  Opportunities for Growth
                </h2>
                <p className="mb-30px">
                  We firmly believe in ensuring all employees get a fair
                  opportunity, a safe environment to express ideas and make
                  suggestions, and the flexibility to meet their business goals
                  in a way that works for their life.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* end */}

      {/* Career at EA Foods start */}
      <section className="section-padding bg-primary text-white">
        <SectionHeading
          className="pb-20px"
          isDark={true}
          sectionTitle="Career at EA Foods"
          sectionPara="We believe strongly in the interconnectedness of human beings. This is why we encourage unity and celebrate
                    diversity.We are One Culture, One People, One Company. We are a Tribe; The EAFoods Tribe. We take pride in
                       the many cultures and genders represented in our workforce as they help us better serve our vast and diverse
                    customer base."
        />
        <Container>
          <Row>
            <Col md={4} lg={4} className="mt-30px">
              <CareerCard
                title="Why choose us?"
                description="Whether you’re driven by data, a sales person or passionate about customer service, our diverse business will provide endless opportunities to grow your career. It’s a place where your talent and skills can take you in unexpected directions."
              />
            </Col>
            <Col md={4} lg={4} className="mt-30px">
              <CareerCard
                title="Where do you fit?"
                description="Whatever level you are at in your career, we offer plenty of opportunities to build a rewarding career at EA Foods. We continuously expand our tribe with smart and innovative people who thrive on collaboration and value creation."
              />
            </Col>
            <Col md={4} lg={4} className="mt-30px">
              <CareerCard
                title="Working at EA Foods"
                description="Our people are our most valuable assets, and this shows in our support for one another. Our goal of becoming the best Company by delivering memorable experiences has become an essential part of our staff’s everyday work."
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Career at EA Foods end */}

      {/* Employee’s Success Story start */}
      {/*<section className="section-padding">*/}
      {/*  <SectionHeading*/}
      {/*    className="pb-50px"*/}
      {/*    sectionTitle="Employee’s Success Story"*/}
      {/*    sectionPara={*/}
      {/*      currentOpenings.length !== 0*/}
      {/*        ? "Success Stories from Customers Cum voluptas rerum et dolores autem est quam error qui eligendi eveniet ut ."*/}
      {/*        : ""*/}
      {/*    }*/}
      {/*  />*/}
      {/*  <Container>*/}
      {/*    <Row>*/}
      {/*      {stories.length === 0 ? (*/}
      {/*        <NoRecords />*/}
      {/*      ) : (*/}
      {/*        stories.map((item: any) => {*/}
      {/*          return (*/}
      {/*            <>*/}
      {/*              <Col md="12" lg="6" className="mt-30px">*/}
      {/*                <a*/}
      {/*                  className="text-dark cursor-pointer"*/}
      {/*                  onClick={() => {*/}
      {/*                    navigate(*/}
      {/*                        `/success-stories/${item.slug}`*/}
      {/*                    );*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  <StoryCard*/}
      {/*                    title={item.story_title}*/}
      {/*                    name={item.firstname}*/}
      {/*                    lastname={item.lastname}*/}
      {/*                    subtitle={item.subtitle}*/}
      {/*                    description={item.story_short_description}*/}
      {/*                    category="Employee"*/}
      {/*                    address={item.location_text}*/}
      {/*                    date={dateFormat(item.publish_date).displayDate}*/}
      {/*                    path={item.profile_picture}*/}
      {/*                  />*/}
      {/*                </a>*/}
      {/*              </Col>*/}
      {/*            </>*/}
      {/*          );*/}
      {/*        })*/}
      {/*      )}*/}
      {/*      <Col xs="12" className="text-center mt-30px">*/}
      {/*        {loadMore && (*/}
      {/*          <Button*/}
      {/*            color="primary"*/}
      {/*            outline*/}
      {/*            className="btn-rounded"*/}
      {/*            onClick={() => navigate("/success-stories/employee-stories")}*/}
      {/*          >*/}
      {/*            Show More*/}
      {/*          </Button>*/}
      {/*        )}*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Container>*/}
      {/*</section>*/}
      {/* Employee’s Success Story end */}

      {/* Open Position start */}
      <section className="section-padding" id="openings">
        <SectionHeading
          className="pb-20px"
          sectionTitle="Open Position"
          sectionPara={
            currentOpenings.length !== 0
              ? "Click on “View Details” in the available positions below to learn more and apply."
              : ""
          }
        />
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8}>
              {currentOpenings.length === 0 ? (
                <div className="mt-3">
                  {isLoading ? <IsLoading /> : <NoRecords />}
                </div>
              ) : (
                currentOpenings.map((item: any) => {
                  return (
                    <>
                      <ApplyCard
                        post={item.title}
                        slug={item.slug}
                        address={item.location}
                        jobType={
                          item.jobtype == "PartTime" ? "Part Time" : "Full Time"
                        }
                        id={item.id}
                      />
                    </>
                  );
                })
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {/* Open Position end */}
    </>
  );
};

export default MasterLayout(Career);
